import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby-plugin-intl"
import SEO from "components/molecules/SEO"
import Layout from "components/molecules/Layout"
import Notification from "components/content/Notification"
import getNotificationData from "utils/getNotificationData"

const NotificationPage = ({ location, pageContext: { language } }) => {
  const [token, setToken] = useState()
  const [ids, setIds] = useState()
  const [data, setData] = useState(null)

  useEffect(() => {
    const getParams = location.search.split("&")
    const getToken = getParams[0].replace("?token=", "")
    const getIds = () => {
      if (getParams.length > 1) {
        return getParams[1].includes("amp;")
          ? getParams[1].replace("amp;ids=", "")
          : getParams[1].replace("ids=", "")
      }
      return null
    }

    if (!getToken && !getIds) {
      navigate("/")
    } else {
      setIds(getIds)
      setToken(getToken)
    }
  }, [])

  useEffect(() => {
    if (token) {
      const getData = async () => {
        const response = await getNotificationData(token, ids)

        if (
          (response && response.errors) ||
          (response && response.data.subscriptions.subscription === null)
        ) {
          navigate("/")
        } else {
          setData(response.data.subscriptions.subscription.notifications)
        }
      }

      getData()
    }
  }, [token, ids])

  return (
    <Layout language={language}>
      <SEO title="Notification" />
      {data && <Notification sectionData={data} />}
    </Layout>
  )
}

NotificationPage.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
  pageContext: PropTypes.shape({
    language: PropTypes.string,
  }).isRequired,
}

export default NotificationPage
