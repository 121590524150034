const getNotificationData = async (dataToken, dataIds) => {
  const response = await fetch(process.env.GATSBY_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic  ${process.env.GATSBY_API_BASE_AUTH}`,
    },
    body: JSON.stringify({
      query: `query{
        subscriptions(token: "${dataToken}") {
          subscription {
            notifications(deploymentsIds: [${dataIds}]) {
              projectsRelatedData {
                projectName
                newProjects {
                  projectLink
                }
                overviews {
                  statusDisplay
                  lastStatusDisplay
                }
                progressStages {
                  statusDisplay
                  lastStatusDisplay
                  stage {
                    nameDisplay
                  }
                  project {
                    slug
                  }
                }
                progressMilestones {
                  title
                  lastStatusDisplay
                  statusDisplay
                }
                timelineposts {
                  title
                  category
                  projectTimelinepostLink
                }
              }
              globalAlertsBanners {
                message
                buttonDestination
              }
              articles {
                articleTitle
                articleLink
              }
              displayProjectsSection
              unsubscribeLink
              email
            }
          }
        }
      }`,
      variables: null,
    }),
  })
  const body = await response.json()
  return body
}

export default getNotificationData
