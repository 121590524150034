/* eslint-disable react/no-array-index-key */
import React from "react"
import PropTypes from "prop-types"
import { useIntl } from "gatsby-plugin-intl"
import paths from "utils/paths"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

import P from "components/atoms/P"
import {
  detailsTitleStyles,
  textStyles,
  signatureStyles,
  bottomTextStyles,
  StyledSectionWrapper,
  StyledSection,
  StyledContentContainer,
  StyledDetailsWrapper,
  StyledListWrapper,
  StyledList,
  StyledListItem,
  StyledTextWrapper,
  StyledSeparator,
  StyledButton,
  StyledBottomTextWrapper,
  StyledCopyrightsWrapper,
  StyledBottomSeparator,
  StyledTopSeparator,
  StyledTopLogoWrapper,
  StyledLogoExdev,
  StyledBottomLogoWrapper,
  StyledImageWrapper,
} from "./Notification.styles"

const imageNotificationQuery = graphql`
  query ImageNotification {
    file(relativePath: { eq: "notification-image.png" }) {
      childImageSharp {
        fluid {
          base64
          aspectRatio
          src
          srcSet
          sizes
        }
      }
    }
  }
`

const getTimelinePostsCategories = (category) => {
  const categories = {
    UPDATE: "Update",
    VIDEO_REPORT: "Video report",
    QUARTERLY_REPORT: "Quarterly report",
  }
  return categories[category]
}

const renderProjectInfo = (projectData, idx) => {
  const { formatMessage } = useIntl()

  if (projectData.newProjects && projectData.newProjects.length > 0) {
    return (
      <StyledListItem>
        <P>
          {`${formatMessage({
            id: "pages.notification.project.newProject",
          })} `}
          <a href={projectData.newProjects[0].projectLink}>
            {formatMessage({
              id: "pages.notification.button.clickHere",
            })}
          </a>
          {` ${formatMessage(
            {
              id: "pages.notification.project.checkNewProject",
            },
            {
              project: projectData.projectName,
            }
          )}`}
        </P>
      </StyledListItem>
    )
  }
  return (
    <StyledListItem key={`${projectData.projectName}${idx}`}>
      <span>{projectData.projectName}</span>
      {projectData.overviews.length > 0 &&
        projectData.overviews.map(
          (overview) =>
            ` (Status: ${overview.lastStatusDisplay} ➡️ ${overview.statusDisplay})`
        )}
      <StyledList>
        <>
          {projectData.timelineposts && projectData.timelineposts.length > 0 && (
            <>
              <li>
                {formatMessage({
                  id: "pages.notification.titles.newTimelinePosts",
                })}
                (
                <a href={projectData.timelineposts[0].projectTimelinepostLink}>
                  {formatMessage({
                    id: "pages.notification.button.timeline",
                  })}
                </a>
                )
              </li>
              <StyledList>
                {projectData.timelineposts.map((post) => (
                  <StyledListItem key={post.title}>
                    <a href={post.projectTimelinepostLink}>
                      {formatMessage({
                        id: "pages.notification.button.clickHere",
                      })}
                    </a>
                    {` ${formatMessage(
                      {
                        id: "pages.notification.timeline.post",
                      },
                      {
                        post: post.title,
                        category: getTimelinePostsCategories(post.category),
                      }
                    )}`}
                  </StyledListItem>
                ))}
              </StyledList>
            </>
          )}
        </>
        <>
          {(projectData.progressStages &&
            projectData.progressStages.length > 0) ||
          (projectData.progressMilestones &&
            projectData.progressMilestones.length > 0) ? (
            <>
              <li>
                <P>
                  {`${formatMessage({
                    id: "pages.notification.titles.progressUpdate",
                  })} `}
                  <a
                    href={paths.portfolioProjectProgress(
                      projectData.progressStages[0].project.slug
                    )}
                  >
                    {formatMessage({
                      id: "pages.notification.button.progress",
                    })}
                  </a>
                </P>
              </li>
              <StyledList>
                {projectData.progressMilestones.length > 0 &&
                  projectData.progressStages.length > 0 && (
                    <>
                      <StyledListItem>
                        {`${projectData.progressStages.stage.nameDisplay} (Status: ${projectData.progressStages.stage.lastStatusDisplay} ➡️ ${projectData.progressStages.stage.statusDisplay})`}
                      </StyledListItem>
                      <StyledList>
                        {projectData.progressMilestones.map((milestone) => (
                          <StyledListItem key={milestone.title}>
                            {`${milestone.title} (Status: ${milestone.lastStatusDisplay} ➡️ ${milestone.statusDisplay})`}
                          </StyledListItem>
                        ))}
                      </StyledList>
                    </>
                  )}

                {projectData.progressMilestones.length === 0 &&
                  projectData.progressStages.length > 0 &&
                  projectData.progressStages.map((stage) => (
                    <StyledListItem key={stage.stage.nameDisplay}>
                      {`${stage.stage.nameDisplay} (Status: ${stage.lastStatusDisplay} ➡️ ${stage.statusDisplay})`}
                    </StyledListItem>
                  ))}
                {projectData.progressStages.length === 0 &&
                  projectData.progressMilestones.length > 0 &&
                  projectData.progressMilestones.map((milestone) => (
                    <StyledListItem key={milestone.title}>
                      {`${milestone.title} (Status: ${milestone.lastStatusDisplay} ➡️ ${milestone.statusDisplay})`}
                    </StyledListItem>
                  ))}
              </StyledList>
            </>
          ) : (
            ""
          )}
        </>
      </StyledList>
    </StyledListItem>
  )
}

const Notification = ({ sectionData }) => {
  const { formatMessage } = useIntl()
  const { file } = useStaticQuery(imageNotificationQuery)
  const {
    articles,
    projectsRelatedData,
    globalAlertsBanners,
    email,
    unsubscribeLink,
    displayProjectsSection,
  } = sectionData
  return (
    <StyledSectionWrapper>
      <StyledSection>
        <StyledTopLogoWrapper>
          <StyledLogoExdev />
        </StyledTopLogoWrapper>

        <StyledTopSeparator />
        <StyledContentContainer>
          {globalAlertsBanners && globalAlertsBanners.length > 0 && (
            <StyledDetailsWrapper>
              <P extraStyles={detailsTitleStyles}>
                <span role="img" aria-label="Announcement">
                  📣
                </span>
                {formatMessage({
                  id: "pages.notification.titles.announcement",
                })}
              </P>
              <StyledListWrapper>
                <StyledList>
                  {globalAlertsBanners.map((announcement, idx) => (
                    <StyledListItem key={`${announcement.message}${idx}`}>
                      <P>
                        {`${announcement.message} `}
                        <a href={announcement.buttonDestination}>
                          {formatMessage({
                            id: "pages.notification.button.learnMore",
                          })}
                        </a>
                      </P>
                    </StyledListItem>
                  ))}
                </StyledList>
              </StyledListWrapper>
            </StyledDetailsWrapper>
          )}
          {projectsRelatedData &&
            projectsRelatedData.length > 0 &&
            displayProjectsSection && (
              <StyledDetailsWrapper>
                <P extraStyles={detailsTitleStyles}>
                  <span role="img" aria-label="Project updates">
                    📈
                  </span>
                  {` ${formatMessage({
                    id: "pages.notification.titles.projectUpdates",
                  })}`}
                </P>
                <StyledListWrapper>
                  <StyledList>
                    {projectsRelatedData.map((project, idx) => (
                      <>{renderProjectInfo(project, idx)}</>
                    ))}
                  </StyledList>
                </StyledListWrapper>
              </StyledDetailsWrapper>
            )}
          {articles && articles.length > 0 && (
            <StyledDetailsWrapper>
              <P extraStyles={detailsTitleStyles}>
                <span role="img" aria-label="Project updates">
                  ✍️
                </span>
                {` ${formatMessage({
                  id: "pages.notification.titles.newBlogArticles",
                })}`}
              </P>
              <StyledListWrapper>
                <StyledList>
                  {articles.map((article, idx) => (
                    <StyledListItem key={`${article.articleTitle}${idx}`}>
                      <a href={article.articleLink}>
                        {formatMessage({
                          id: "pages.notification.button.clickHere",
                        })}
                      </a>
                      {` ${formatMessage(
                        {
                          id: "pages.notification.titles.newBlogArticles",
                        },
                        {
                          article: article.articleTitle,
                        }
                      )}`}
                    </StyledListItem>
                  ))}
                </StyledList>
              </StyledListWrapper>
            </StyledDetailsWrapper>
          )}
          <StyledTextWrapper>
            <P extraStyles={textStyles}>
              {formatMessage({
                id: "pages.notification.information.description",
              })}
            </P>
            <P extraStyles={textStyles}>
              {`${formatMessage({
                id: "pages.notification.information.questions",
              })} `}
              <a href="mailto:info@exdev.com">info@exdev.com</a>.
            </P>
            <P extraStyles={textStyles}>
              {formatMessage({
                id: "pages.notification.information.signatureThanks",
              })}
            </P>
            <P extraStyles={signatureStyles}>
              {formatMessage({
                id: "pages.notification.information.signature",
              })}
            </P>
          </StyledTextWrapper>
          <StyledSeparator />
          <StyledImageWrapper>
            <Img fluid={file.childImageSharp.fluid} />
          </StyledImageWrapper>
          <StyledButton to={paths.home}>Visit now</StyledButton>
        </StyledContentContainer>
      </StyledSection>
      <StyledBottomTextWrapper>
        <StyledBottomLogoWrapper>
          <a href={paths.home}>
            <StyledLogoExdev />
          </a>
        </StyledBottomLogoWrapper>
        <P extraStyles={bottomTextStyles}>
          {`${formatMessage({
            id: "pages.notification.footer.sentTo",
          })} `}
          <a href={`mailto:${email}`}>{email}</a>
        </P>
        <P extraStyles={bottomTextStyles}>
          {formatMessage({
            id: "pages.notification.footer.why",
          })}
        </P>
        <P extraStyles={bottomTextStyles}>
          {`${formatMessage({
            id: "pages.notification.footer.contact",
          })} `}
          <a href="mailto:info@exdev.com">info@exdev.com</a>.
        </P>
        <P extraStyles={bottomTextStyles}>
          {formatMessage({
            id: "pages.notification.footer.website",
          })}
          <a href={paths.home}>
            {`${formatMessage({
              id: "pages.notification.button.clickHere",
            })}!`}
          </a>
        </P>
        <StyledCopyrightsWrapper>
          <P extraStyles={bottomTextStyles}>
            {formatMessage({
              id: "pages.notification.footer.copyrights",
            })}
          </P>
          <P extraStyles={bottomTextStyles}>
            {formatMessage({
              id: "pages.notification.footer.address",
            })}
          </P>
        </StyledCopyrightsWrapper>
        <StyledBottomSeparator />
        <P extraStyles={bottomTextStyles}>
          {`${formatMessage({
            id: "pages.notification.footer.unsubscribe",
          })} `}
          <a href={unsubscribeLink}>
            {formatMessage({
              id: "pages.notification.button.unsubscribe",
            })}
          </a>
        </P>
      </StyledBottomTextWrapper>
    </StyledSectionWrapper>
  )
}

Notification.propTypes = {
  sectionData: PropTypes.shape({
    articles: PropTypes.arrayOf(PropTypes.shape({})),
    projectsRelatedData: PropTypes.arrayOf(PropTypes.shape({})),
    globalAlertsBanners: PropTypes.arrayOf(PropTypes.shape({})),
    email: PropTypes.string,
    unsubscribeLink: PropTypes.string,
    displayProjectsSection: PropTypes.bool.isRequired,
  }),
}

Notification.defaultProps = {
  sectionData: {},
}

export default Notification
