import styled, { css } from "styled-components"
import LogoExdev from "assets/logos/exdev.svg"
import BtnLink from "components/atoms/BtnLink"

const detailsTitleStyles = css`
  font-size: ${({ theme }) => theme.font.size.s};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`
const textStyles = css`
  margin-bottom: 1.6rem;
`

const signatureStyles = css`
  ${textStyles}
  font-style: italic;
`

const StyledSectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.color.secondary05};
  padding: 10rem ${({ theme }) => theme.layout.mobilePadding} 0;
`

const StyledSection = styled.div`
  max-width: 60rem;
  margin: 0 auto;
  height: auto;
  background: ${({ theme }) => theme.color.white};
  border: 1px dotted ${({ theme }) => theme.color.secondary03};
`

const StyledContentContainer = styled.div`
  padding: 2rem;
`

const StyledDetailsWrapper = styled.div`
  margin: 4.8rem 0;
  line-height: 1.7;

  &:first-of-type {
    margin-top: 1.6rem;
  }

  a {
    text-decoration: underline;
  }
`

const StyledListWrapper = styled.div`
  margin: 1.6rem 0;
`

const StyledList = styled.ul`
  padding-left: 2rem;
  margin: 0.8rem 0;
`
const StyledListItem = styled.li`
  font-size: ${({ theme }) => theme.font.size.s};
  margin: 0.8rem 0;

  span {
    ${detailsTitleStyles}
  }
`
const StyledTextWrapper = styled.div`
  width: 100%;
  font-size: ${({ theme }) => theme.font.size.s};
`

const StyledSeparator = styled.div`
  width: 80%;
  border-bottom: 1px solid ${({ theme }) => theme.color.secondary04};
  margin: 3.2rem auto;
`

const StyledButton = styled(BtnLink)`
  border: 0;
  outline: none;
  background: ${({ theme }) => theme.color.secondary01};
  color: ${({ theme }) => theme.color.white};
  cursor: pointer;
  line-height: 2.2rem;
  padding: 2rem 8rem;
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin: 0 auto;
  display: block;
  width: fit-content;
`

const StyledBottomTextWrapper = styled.div`
  width: 100%;
  text-align: center;
  padding: 3.2rem 0 1.6rem;

  a {
    text-decoration: underline;
  }
`

const bottomTextStyles = css`
  color: ${({ theme }) => theme.color.secondary03};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
`

const StyledCopyrightsWrapper = styled.div`
  margin: 3.2rem 0;
`
const StyledBottomSeparator = styled.div`
  max-width: 60rem;
  border-bottom: 1px dotted ${({ theme }) => theme.color.secondary04};
  margin: 0 auto 0.8rem;
`
const StyledTopSeparator = styled.div`
  width: 100%;
  border-bottom: 1px dotted ${({ theme }) => theme.color.secondary02};
`
const StyledTopLogoWrapper = styled.div`
  height: 4rem;
  padding: 1.6rem;
  box-sizing: content-box;

  svg {
    fill: ${({ theme }) => theme.color.secondary01};
  }
`
const StyledLogoExdev = styled(LogoExdev)`
  height: 100%;
`

const StyledBottomLogoWrapper = styled.div`
  height: 2.8rem;
  margin-bottom: 3.2rem;

  svg {
    fill: ${({ theme }) => theme.color.secondary03};
  }
`
const StyledImageWrapper = styled.div`
  margin: 3.2rem 0;
`

export {
  detailsTitleStyles,
  textStyles,
  signatureStyles,
  bottomTextStyles,
  StyledSectionWrapper,
  StyledSection,
  StyledContentContainer,
  StyledDetailsWrapper,
  StyledListWrapper,
  StyledList,
  StyledListItem,
  StyledTextWrapper,
  StyledSeparator,
  StyledButton,
  StyledBottomTextWrapper,
  StyledCopyrightsWrapper,
  StyledBottomSeparator,
  StyledTopSeparator,
  StyledTopLogoWrapper,
  StyledLogoExdev,
  StyledBottomLogoWrapper,
  StyledImageWrapper,
}
